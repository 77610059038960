.custom-gradient-background {
    background: linear-gradient(to bottom, transparent 30%, red 90%);
    background-size: 100% 200%;
    background-position: top;
    transition: background-position 0.5s ease-in-out;
  }
  
  .custom-gradient-background:hover {
    background-position: bottom;
  }


  .check {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; /* Change from cover to contain */
    width: 100%;
    height: 100%;
}
/* Laptops/desktops with smaller screens */
  /* @media screen and (max-wi
  dth: 75em) { 
    .carousel-adjust {
      height: 100%;
  }
} */

/* Tablets and large phones */
/* @media screen and (max-width: 62em) { 
    .carousel-adjust {
        height: 100%;
    }

} */

/* Tablets and medium phones */
/* @media screen and (max-width: 48em) { 
  .carousel-adjust {
    height: 100%;
}
} */

/* Small phones */
/* @media screen and (max-width: 30em) { 
  .carousel-adjust {
    height: 100%;
}
} */
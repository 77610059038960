.whatsapp-icon {
    position: fixed;
    bottom: 1rem; 
    right: 1rem;  
    background-color: #25D366; 
    color: white;
    border-radius: 50%;
    padding: 1rem;
    font-size: 2rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); 
    z-index: 1000; 
    transition: background-color 0.3s;
  }
  
  .whatsapp-icon:hover {
    background-color: #128C7E; 
  }
  
/* Common styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.header {
    padding: 0 2rem;
    height: 4.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.header.transparent {
    background-color: transparent;
    box-shadow: none;
}

.header.scrolled {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds shadow for depth */
}

.web-logo {
    display: flex;
    align-items: center;
    height: 100%; /* Set to 100% to fit within the header height */
}

.logo {
    height: 30px;
    width: auto;
    transform: scale(1.5); 
    transform-origin: left center; /* Scale from the left center */
    fill: white; /* Scale from the left center */
}

.navbar {
    display: flex;
    align-items: center;
}

.navbar-list {
    display: flex;
    gap: 2rem; /* Adjust gap for better spacing */
    list-style: none;
    align-items: center;
    margin-top: 0;
}

.navbar-link {
    text-transform: uppercase;
    text-decoration: none;
    color: #000000; /* Updated color to white for better contrast on red background */
    font-size: 1.2rem;
    font-weight: 600; /* Slightly bolder text */
    transition: color 0.3s, border-bottom 0.3s;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); 
}

.navbar-link:hover,
.navbar-link:active {
    color: black; /* Changed hover color to yellow for better visibility on red */
    border-bottom: 2px solid black; /* Underline effect on hover */
}

.mobile-navbar-btn {
    display: none;
    background: transparent;
    cursor: pointer;
    z-index: 999;
}

.mobile-nav-icon {
    font-size: 1rem; /* Adjust icon size */
    color: black; /* Match text color to white for better contrast */
}

/* Media query for mobile view */
@media screen and (max-width: 62em) {

    .header {
        background-color: #ffffff !important;
    }
    
    .mobile-navbar {
        width: 100%;
        background: #cfd1d1; /* Changed background color to red */
        position: absolute;
        top: 4rem;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 999;
        padding: 1rem 0;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow to the mobile menu */
    }

    .mobile-navbar-list {
        flex-direction: column;
        align-items: center;
        display: flex;
        list-style: none;
        padding: 0;
    }

    .mobile-navbar-btn {
        display: inline-block;
        color: #ffffff; /* Updated button color to white */
    }

    .navbar-list {
        display: none;
    }
}
